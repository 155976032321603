.documents-manage {
  padding: 5px 0;
}

.documents-manage__action-buttons {
  button {
    min-width: 85px;
  }

  .loading-spinner--small {
    width: 18px;
    height: 18px;
  }
}

.documents-manage__checkbox {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

.documents-manage__table {
  margin: 25px 0;
}

.documents-manage--refund-btn {
  margin-right: 15px;
}
